import React from "react"
import { graphql } from "gatsby"
import { Parallax } from "react-parallax"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  Container,
  Box,
  Grid,
  GridItem,
  Stack,
  Table,
  Tbody,
  Td,
  Tr,
  Link,
} from "@chakra-ui/react"
import SubpageParallaxImageHero from "../components/partials/subpage-parallax-image-hero"

interface Props {
  data: {
    allContentfulSiteImages: {
      edges: {
        node: {
          image: {
            url: string
          }
        }
      }[]
    }
    allContentfulCompanyProfile: {
      edges: {
        node: {
          name: string
          address: any
          tel: string
          email: string
          capital: string
          representativeName: string
          establishedOn: string
          license: string
          community: string
          workingHour: string
          holidays: string
        }
      }[]
    }
  }
}

const CompanyPage: React.FC<Props> = ({ data }) => {
  const {
    name,
    address: { address },
    tel,
    email,
    capital,
    representativeName,
    establishedOn,
    license,
    community,
    workingHour,
    holidays,
  } = data.allContentfulCompanyProfile.edges[0].node

  return (
    <Layout>
      <Seo
        title="会社紹介"
        description="会社紹介"
        image={data.allContentfulSiteImages.edges[0].node.image.url}
      />
      {data.allContentfulSiteImages.edges.map(({ node: siteImage }: any) => (
        <SubpageParallaxImageHero
          url={siteImage.image.url}
          caption={`会社紹介`}
          fontFamily="sans"
        />
      ))}
      <Container maxW="container.xl" as={`main`} p={0}>
        <Stack as={`section`} spacing={8} pb={{ base: 8, lg: 24 }} pt={8}>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }}
            gap={6}
          >
            <GridItem w="100%" h={{ base: "300px", lg: `100%` }}>
              <Box
                as={`iframe`}
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d6560.288574218436!2d138.76684087765113!3d34.70154050000412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzTCsDQxJzU1LjkiTiAxMzjCsDQ2JzE1LjkiRQ!5e0!3m2!1sja!2sjp!4v1712303981140!5m2!1sja!2sjp"
                className="w-full"
                height="100%"
                width={`100%`}
                title={`map`}
              ></Box>
            </GridItem>
            <GridItem w="100%" px={{ base: 4, lg: 0 }}>
              <Table
                variant="unstyled"
                overflowWrap={`break-word`}
                fontSize={{ base: 12, lg: 14 }}
              >
                {/* <TableCaption>会社情報</TableCaption>
                <Thead>
                  <Tr>
                    <Th w={`30%`} whiteSpace="unset">
                      項目
                    </Th>
                    <Th w={`70%`}>詳細</Th>
                  </Tr>
                </Thead> */}
                <Tbody>
                  <Tr
                    borderTop={`1px`}
                    borderBottom={`1px`}
                    borderColor={`gray.200`}
                  >
                    <Td
                      bg={`blackAlpha.500`}
                      color={`white`}
                      fontWeight={`bold`}
                    >
                      商号
                    </Td>
                    <Td>{name}</Td>
                  </Tr>
                  <Tr
                    borderTop={`1px`}
                    borderBottom={`1px`}
                    borderColor={`gray.200`}
                  >
                    <Td
                      bg={`blackAlpha.50`}
                      color={`blackAlpha.800`}
                      fontWeight={`bold`}
                    >
                      本店
                    </Td>
                    <Td overflowWrap={`break-word`}>{address}</Td>
                  </Tr>
                  <Tr
                    borderTop={`1px`}
                    borderBottom={`1px`}
                    borderColor={`gray.200`}
                  >
                    <Td
                      bg={`blackAlpha.50`}
                      color={`blackAlpha.800`}
                      fontWeight={`bold`}
                    >
                      電話
                    </Td>
                    <Td overflowWrap={`break-word`}>
                      <Link href="tel:+81-558-64-8880">{tel}</Link>
                    </Td>
                  </Tr>
                  <Tr
                    borderTop={`1px`}
                    borderBottom={`1px`}
                    borderColor={`gray.200`}
                  >
                    <Td
                      bg={`blackAlpha.50`}
                      color={`blackAlpha.800`}
                      fontWeight={`bold`}
                    >
                      メールアドレス
                    </Td>
                    <Td colSpan={3} overflowWrap={`break-word`}>
                      <Link href="mailto:sunlife@ceres.ocn.ne.jp">{email}</Link>
                    </Td>
                  </Tr>
                  <Tr
                    borderTop={`1px`}
                    borderBottom={`1px`}
                    borderColor={`gray.200`}
                  >
                    <Td
                      bg={`blackAlpha.50`}
                      color={`blackAlpha.800`}
                      fontWeight={`bold`}
                    >
                      資本金
                    </Td>
                    <Td colSpan={3} overflowWrap={`break-word`}>
                      {capital}万円
                    </Td>
                  </Tr>
                  <Tr
                    borderTop={`1px`}
                    borderBottom={`1px`}
                    borderColor={`gray.200`}
                  >
                    <Td
                      bg={`blackAlpha.50`}
                      color={`blackAlpha.800`}
                      fontWeight={`bold`}
                    >
                      代表者
                    </Td>
                    <Td colSpan={3} overflowWrap={`break-word`}>
                      {representativeName}
                    </Td>
                  </Tr>
                  <Tr
                    borderTop={`1px`}
                    borderBottom={`1px`}
                    borderColor={`gray.200`}
                  >
                    <Td
                      bg={`blackAlpha.50`}
                      color={`blackAlpha.800`}
                      fontWeight={`bold`}
                    >
                      会社設立
                    </Td>
                    <Td colSpan={3} overflowWrap={`break-word`}>
                      {establishedOn}
                    </Td>
                  </Tr>
                  <Tr
                    borderTop={`1px`}
                    borderBottom={`1px`}
                    borderColor={`gray.200`}
                  >
                    <Td
                      bg={`blackAlpha.50`}
                      color={`blackAlpha.800`}
                      fontWeight={`bold`}
                    >
                      免許番号
                    </Td>
                    <Td colSpan={3} overflowWrap={`break-word`}>
                      {license}
                    </Td>
                  </Tr>
                  <Tr
                    borderTop={`1px`}
                    borderBottom={`1px`}
                    borderColor={`gray.200`}
                  >
                    <Td
                      bg={`blackAlpha.50`}
                      color={`blackAlpha.800`}
                      fontWeight={`bold`}
                    >
                      加入団体
                    </Td>
                    <Td colSpan={3} overflowWrap={`break-word`}>
                      {community}
                    </Td>
                  </Tr>
                  <Tr
                    borderTop={`1px`}
                    borderBottom={`1px`}
                    borderColor={`gray.200`}
                  >
                    <Td
                      bg={`blackAlpha.50`}
                      color={`blackAlpha.800`}
                      fontWeight={`bold`}
                    >
                      営業時間
                    </Td>
                    <Td colSpan={3} overflowWrap={`break-word`}>
                      {workingHour}
                    </Td>
                  </Tr>
                  <Tr
                    borderTop={`1px`}
                    borderBottom={`1px`}
                    borderColor={`gray.200`}
                  >
                    <Td
                      bg={`blackAlpha.50`}
                      color={`blackAlpha.800`}
                      fontWeight={`bold`}
                    >
                      定休日
                    </Td>
                    <Td colSpan={3} overflowWrap={`break-word`}>
                      {holidays}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </GridItem>
          </Grid>
        </Stack>
      </Container>
    </Layout>
  )
}

export default CompanyPage

export const query = graphql`
  {
    allContentfulSiteImages(filter: { imageNo: { eq: 9 } }, limit: 1) {
      edges {
        node {
          image {
            url
          }
        }
      }
    }
    allContentfulCompanyProfile {
      edges {
        node {
          name
          address {
            address
          }
          transportation
          tel
          fax
          email
          capital
          representativeName
          establishedOn
          license
          community
          workingHour
          holidays
        }
      }
    }
  }
`
